exports.components = {
  "component---pages-404-js": () => import("./../../../pages/404.js" /* webpackChunkName: "component---pages-404-js" */),
  "component---pages-about-js": () => import("./../../../pages/about.js" /* webpackChunkName: "component---pages-about-js" */),
  "component---pages-annotations-js": () => import("./../../../pages/annotations.js" /* webpackChunkName: "component---pages-annotations-js" */),
  "component---pages-blog-article-js": () => import("./../../../pages/blog-article.js" /* webpackChunkName: "component---pages-blog-article-js" */),
  "component---pages-blog-newsroom-js": () => import("./../../../pages/blog-newsroom.js" /* webpackChunkName: "component---pages-blog-newsroom-js" */),
  "component---pages-company-cookies-js": () => import("./../../../pages/company-cookies.js" /* webpackChunkName: "component---pages-company-cookies-js" */),
  "component---pages-company-privacy-js": () => import("./../../../pages/company-privacy.js" /* webpackChunkName: "component---pages-company-privacy-js" */),
  "component---pages-company-terms-js": () => import("./../../../pages/company-terms.js" /* webpackChunkName: "component---pages-company-terms-js" */),
  "component---pages-contact-us-js": () => import("./../../../pages/contact-us.js" /* webpackChunkName: "component---pages-contact-us-js" */),
  "component---pages-demo-data-js": () => import("./../../../pages/demo-data.js" /* webpackChunkName: "component---pages-demo-data-js" */),
  "component---pages-digital-outcrop-models-js": () => import("./../../../pages/digital-outcrop-models.js" /* webpackChunkName: "component---pages-digital-outcrop-models-js" */),
  "component---pages-geotour-3-d-js": () => import("./../../../pages/geotour3d.js" /* webpackChunkName: "component---pages-geotour-3-d-js" */),
  "component---pages-get-trial-js": () => import("./../../../pages/get-trial.js" /* webpackChunkName: "component---pages-get-trial-js" */),
  "component---pages-index-js": () => import("./../../../pages/index.js" /* webpackChunkName: "component---pages-index-js" */),
  "component---pages-interpretation-js": () => import("./../../../pages/interpretation.js" /* webpackChunkName: "component---pages-interpretation-js" */),
  "component---pages-measured-sections-js": () => import("./../../../pages/measured-sections.js" /* webpackChunkName: "component---pages-measured-sections-js" */),
  "component---pages-not-found-cover-js": () => import("./../../../pages/not-found-cover.js" /* webpackChunkName: "component---pages-not-found-cover-js" */),
  "component---pages-not-found-js": () => import("./../../../pages/not-found.js" /* webpackChunkName: "component---pages-not-found-js" */),
  "component---pages-pricing-academic-js": () => import("./../../../pages/pricing-academic.js" /* webpackChunkName: "component---pages-pricing-academic-js" */),
  "component---pages-pricing-commercial-js": () => import("./../../../pages/pricing-commercial.js" /* webpackChunkName: "component---pages-pricing-commercial-js" */),
  "component---pages-structural-geology-js": () => import("./../../../pages/structural-geology.js" /* webpackChunkName: "component---pages-structural-geology-js" */),
  "component---pages-tour-3-d-flamborough-js": () => import("./../../../pages/tour3d-flamborough.js" /* webpackChunkName: "component---pages-tour-3-d-flamborough-js" */),
  "component---pages-tour-3-d-htmuseum-js": () => import("./../../../pages/tour3d-htmuseum.js" /* webpackChunkName: "component---pages-tour-3-d-htmuseum-js" */),
  "component---pages-tour-3-d-ormerods-js": () => import("./../../../pages/tour3d-ormerods.js" /* webpackChunkName: "component---pages-tour-3-d-ormerods-js" */),
  "component---pages-tour-3-d-staithes-js": () => import("./../../../pages/tour3d-staithes.js" /* webpackChunkName: "component---pages-tour-3-d-staithes-js" */),
  "component---pages-tour-3-d-torrs-js": () => import("./../../../pages/tour3d-torrs.js" /* webpackChunkName: "component---pages-tour-3-d-torrs-js" */),
  "component---pages-tour-3-d-trefor-rocks-js": () => import("./../../../pages/tour3d-trefor-rocks.js" /* webpackChunkName: "component---pages-tour-3-d-trefor-rocks-js" */),
  "component---pages-tour-3-d-whitby-js": () => import("./../../../pages/tour3d-whitby.js" /* webpackChunkName: "component---pages-tour-3-d-whitby-js" */),
  "component---pages-tour-3-d-wrens-nest-js": () => import("./../../../pages/tour3d-wrens-nest.js" /* webpackChunkName: "component---pages-tour-3-d-wrens-nest-js" */),
  "component---pages-upgrade-js": () => import("./../../../pages/upgrade.js" /* webpackChunkName: "component---pages-upgrade-js" */),
  "component---pages-virtual-field-trips-js": () => import("./../../../pages/virtual-field-trips.js" /* webpackChunkName: "component---pages-virtual-field-trips-js" */),
  "component---pages-visualisation-js": () => import("./../../../pages/visualisation.js" /* webpackChunkName: "component---pages-visualisation-js" */),
  "component---pages-vrgeoscience-js": () => import("./../../../pages/vrgeoscience.js" /* webpackChunkName: "component---pages-vrgeoscience-js" */)
}

